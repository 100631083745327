import { BeautifulNumber } from '@/store/api/beautiful-numbers/beautiful-numbers.type';

export function checkCountOfNumbers(obj: Record<string, BeautifulNumber[]> | undefined, limit: number) {
  if (!obj) return;

  return Object.values(obj).every((el) => el.length >= limit);
}

function replaceSymbols(str: string) {
  if (!str || !str.length) {
    return '';
  }
  const symbolsToExclude = /[() -]/g;
  const charArray = Array.from(str);

  return charArray.map((char, index) => {
    if (symbolsToExclude.test(char)) {
      return (
        <span key={char + index}>{char}</span>
      );
    } else {
      return char;
    }
  });
}

export function highlightSubsting(str: string, substr: string) {
  let reStr = '(.*)(';
  for (let i = 0; i < substr.length; i++) {
    reStr = reStr + substr.charAt(i);
    if (i !== substr.length - 1) {
      reStr = reStr + '[()]?[ -]?';
    }
  }
  reStr = reStr + ')(.*)';
  const re = new RegExp(reStr, 'gm');
  const res_ = re.exec(str);
  const marked = res_ != null ? (
    <>
      {res_[1]}
      <mark>{replaceSymbols(res_[2])}</mark>
      {res_[3]}
    </>
  ) : str;

  return marked;
}
