'use client';

import React, { useState, useEffect, useMemo, useRef, HTMLAttributes } from 'react';
import { Button } from '@mantine/core';
import { isAfter } from 'date-fns';
import cn from 'classnames';
import { Reset } from '@/shared/assets/svg';
import { DiamondBtn, RefreshBtn, ShoppingBasket } from '@/shared/assets/icons';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setBeautifulNumber, SliceBeautifulNumber } from '@/store/slice/beautiful-number';
import { BeautifulNumberModal } from '@/widgets/beautiful-numbers-modal/beautiful-numbers-modal';
import { BeautifulNumber, NumberCategory } from '@/store/api/beautiful-numbers/beautiful-numbers.type';
import { replaceNumber, rollCylinder } from '@/widgets/beautiful-numbers/beautiful-numbers.utils';
import { INDEX, HEIGHT, OFFSET, animationDelay, animationDuration, categoryPriceMap } from '@/widgets/beautiful-numbers/beautiful-numbers.constants';
import { MobileTariffMappedOptions } from '@/modules/tariff/shared/helpers';
import { resetBeautifulNumber } from '@/store/slice/beautiful-number';
import { YM } from '@/shared/constants/yandex-metrika';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { reachGoal } from '@/shared/lib/ym';
import { blackFridayDeadline } from '@/shared/constants/black-friday';
import { formatPrice } from '@/shared/lib/utils';
import { formatPhoneNumber } from '@/shared/lib/format-phone-number';
import { volnaTheme as theme } from '@/shared/constants/theme';
import { ImageForTariffPage } from './image-for-tariff-page';
import { BasketBeautifulNumber } from './basket-beautiful-number/basket-beautiful-number';
import styles from './beautiful-numbers.module.scss';

interface BeautifulNumbersProps extends HTMLAttributes<HTMLDivElement> {
  onShowForm?: VoidFunction;
  beautifulNumbers: BeautifulNumber[];
  activeTariffData?: MobileTariffMappedOptions;
  numberCategories?: NumberCategory[];
  number?: string;
  page?: 'main' | 'tariff';
  price?: number;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading: boolean;
}

function BeautifulNumbers({
  className,
  onShowForm,
  beautifulNumbers,
  activeTariffData,
  numberCategories,
  page,
  onClick,
  isDisabled,
  isLoading,
}: BeautifulNumbersProps) {
  const dispatch = useAppDispatch();
  const { selectedNumber } = useAppSelector((state) => state.beautifulNumber);
  const { isMobile, isDesktopMax } = useMediaQuery();
  const [isAnimating, setIsAnimating] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const currentNumberRef = useRef<SliceBeautifulNumber>();
  const [numbers, setNumbers] = useState<SliceBeautifulNumber[]>(beautifulNumbers);
  const [counter, setCounter] = useState(0);
  const [bnModal, setBnModal] = useState(false);
  const [activeCategory, setActiveCategory] = useState<NumberCategory>();
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const isTariffPage = page !== 'main';
  const [isAddButtonPressed, setIsAddButtonPressed] = useState(false);
  const [buyButtonCounter, setBuyButtonCounter] = useState(0);
  const numberCategoriesData = numberCategories?.length ? numberCategories : [];

  const excludedIds = new Set([1, 102]); //для акции "Черная пятница"
  const isPromotionExpired = isAfter(new Date(), blackFridayDeadline);
  const shouldDisplayCrossedOutPrice = activeCategory && !excludedIds.has(activeCategory.pht_id) && categoryPriceMap[activeCategory.pht_id] && !isPromotionExpired;

  const handleCloseModal = () => setShowModal(false);

  const handleNextNumberButtonClick = () => {
    if (!isAnimating) {
      setCounter((prev) => prev + 1);
      setIsAnimating(true);
      setActiveCategory(numberCategoriesData?.find(({ pht_id }) => pht_id === currentNumberRef.current?.pht_id));

      setTimeout(() => {
        setIsAnimating(false);

        if (!isTariffPage) {
          dispatch(
            setBeautifulNumber({
              selectedNumber: { ...currentNumberRef.current, price: activeCategory?.price } as BeautifulNumber,
            }),
          );
        }
      }, 850);
    }
  };

  const handleBuyNumberButtonClick = () => {
    if (isAnimating) {
      return;
    }

    if (isTariffPage) {
      setBuyButtonCounter((prev) => prev + 1);
      setIsAddButtonPressed((prevState) => !prevState);

      if (isAddButtonPressed) {
        dispatch(resetBeautifulNumber());
      } else {
        dispatch(
          setBeautifulNumber({
            selectedNumber: { ...currentNumberRef.current, price: activeCategory?.price } as BeautifulNumber,
          }),
        );
      }
    } else {
      dispatch(
        setBeautifulNumber({
          selectedNumber: { ...currentNumberRef.current, price: activeCategory?.price } as BeautifulNumber,
        }),
      );
      setBnModal(true);
      reachGoal(YM.click_beautiful_number);
    }
  };

  const getNumberContent = (num: string, index: number) => {
    const result = [];
    for (let i = 0; i < num?.length; i++) {

      const isNotAnimatingIndex = [0, 1, 2, 6, 10, 13];
      const isNotAnimating = isNotAnimatingIndex.includes(i);
      const fadingAnimation = [2, 6, 10, 13].includes(i);
      result.push(
        <span
          key={`${index}-${i}`}
          style={{
            animationDelay: `${animationDelay[i] ? animationDelay[i] : '0s'}, 0s`,
            animationDuration: `0.5s, ${animationDuration[i] ? animationDuration[i] : '0s'}`,
            textShadow: `${!isDisabled ? '0px 4px 4px rgba(47, 14, 63, 0.25)' : 'none'} `,
          }}
          className={cn({
            [styles.animation]: (isAnimating || isFirstLoad) && !isNotAnimating,
            [styles.fadingAnimation]: (isAnimating || isFirstLoad) && fadingAnimation,
          })}
        >
          {num[i]}
        </span>
      );
    }

    return result;
  };

  const stringifiedNumbers = useMemo(() => {
    return numbers?.map((n) => {
      return formatPhoneNumber(n.msisdn) || '';
    });
  }, [numbers]);

  const leftIconShoppingButton = !isTariffPage || !isAddButtonPressed && !isDisabled ?
    <ShoppingBasket color='black' /> :
    (isAddButtonPressed ? <Reset /> : <ShoppingBasket color={theme.colors.base[500]} />);

  useEffect(() => {
    setTimeout(() => {
      setIsFirstLoad(false);
    }, 2000);
  }, []);

  // Эффект срабатывает по нажатию на кнопку "Следующий номер"
  useEffect(() => {
    if (numbers?.length) {
      const result = rollCylinder(numbers);
      setNumbers(result);
      currentNumberRef.current = result[INDEX];
      setActiveCategory(numberCategoriesData?.find(({ pht_id }) => pht_id === currentNumberRef.current?.pht_id));

      if (!isTariffPage) {
        dispatch(
          setBeautifulNumber({
            selectedNumber: { ...currentNumberRef.current, price: activeCategory?.price } as BeautifulNumber,
          }),
        );
      }

    }
  }, [counter, isTariffPage]);

  // Зачем нужен этот эффект? 
  useEffect(() => {
    if (selectedNumber && selectedNumber.msisdn !== currentNumberRef.current?.msisdn) {
      const result = replaceNumber(selectedNumber, numbers);
      setNumbers(result);
      currentNumberRef.current = selectedNumber;
      setActiveCategory(numberCategoriesData?.find(({ pht_id }) => pht_id === currentNumberRef.current?.pht_id));

      if (isTariffPage) {
        if (buyButtonCounter === 0) {
          dispatch(resetBeautifulNumber());
        } else {
          setIsAddButtonPressed(true);
        }
      }
    }
  }, [selectedNumber, isTariffPage, buyButtonCounter]);

  // Первая установка КН в сторе на Главной странице
  useEffect(() => {
    if (numbers?.length && !selectedNumber && activeCategory && !isTariffPage) {
      dispatch(
        setBeautifulNumber({
          selectedNumber: { ...currentNumberRef.current, price: activeCategory?.price } as BeautifulNumber,
        }),
      );
    }
  }, [activeCategory, dispatch, numbers, selectedNumber, isTariffPage]);

  // Первая установка numbers и activeCategory
  useEffect(() => {
    if (
      (!numbers.length || numbers.length !== beautifulNumbers.length) &&
      beautifulNumbers.length &&
      beautifulNumbers.length > INDEX &&
      numberCategoriesData?.length
    ) {
      setNumbers(beautifulNumbers);
      currentNumberRef.current = beautifulNumbers[INDEX];
      setActiveCategory(
        numberCategoriesData.find(({ pht_id }) => pht_id === currentNumberRef.current?.pht_id)
      );
    }
  }, [beautifulNumbers, numberCategoriesData, numbers, currentNumberRef]);

  const renderNumbers = (numbersToRender: string[]) => {
    return (
      <div className={cn(styles.content, { [styles.content__tariffPage]: isTariffPage })}>
        <div className={cn(styles.numbers, { [styles.numbers__tariffPage]: isTariffPage })}>
          <div className={styles.cylinder}>
            {numbersToRender.map((n, i) => {
              return (
                <div
                  key={i}
                  style={{
                    position: 'absolute',
                    top: i * HEIGHT - OFFSET,
                  }}
                  className={cn(styles.phoneNumber, {
                    [styles.phoneNumber__tariffPage]: isTariffPage,
                    [styles.animation]: isAnimating,
                    [styles.disabled__text]: isDisabled,
                  })}
                >
                  {getNumberContent(n, i)}
                </div>
              );
            })}
          </div>
        </div>

        <div className={cn(styles.content__price, {
          [styles.content__price__tariffPage]: isTariffPage,
          [styles.disabled__text]: isDisabled && isTariffPage,
        })}>
          <span>Стоимость</span>{' '}
          {shouldDisplayCrossedOutPrice && (
            <span className={styles.oldPrice}>{categoryPriceMap[activeCategory?.pht_id]}</span>
          )}{' '}
          {formatPrice(activeCategory?.price)}
        </div>
      </div>
    );
  };

  return (
    <div
      className={cn(isTariffPage ? styles.tariffPage : styles.card, className && className, {
        [styles.card__animated]: isAnimating || isFirstLoad,
        [styles.disabled]: isDisabled && isTariffPage,
      })}>

      {!isMobile && isTariffPage &&
        <ImageForTariffPage isAnimating={isAnimating} isDisabled={isDisabled} isFirstLoad={isFirstLoad} />
      }

      {!isTariffPage && (
        <div>
          <div className={styles.link}>
            <span className={styles.link__text}>Купить красивый номер</span>
          </div>
          <div className={styles.description}>Номер, по которому тебя запомнят</div>
        </div>
      )}

      {/* убрал !isLoading чтобы номера быстрее показались */}
      {numbers.length ? (
        renderNumbers(stringifiedNumbers)
      ) :
        null
      }

      <div className={cn(styles.buttons, { [styles.buttons__tariffPage]: isTariffPage })}>
        <Button
          classNames={{
            root: cn(styles.button__root, styles.buttons__shop, {
              [styles.button__root__tariffPage]: isTariffPage,
              [styles.button__two]: isAddButtonPressed,
            }),
          }}
          onClick={handleBuyNumberButtonClick}
          leftIcon={leftIconShoppingButton}
          disabled={isDisabled}
        >
          {!isTariffPage ? 'Купить' : (isAddButtonPressed ? 'Выбрать другой' : 'Добавить к тарифу')}
        </Button>
        <Button
          leftIcon={<DiamondBtn color={isAddButtonPressed || isDisabled ? theme.colors.base[500] : theme.colors.primary[0]} />}
          classNames={{
            root: cn(styles.button__root, styles.button__two, styles.buttons__all,
              {
                [styles.button__root__tariffPage]: isTariffPage,
                [styles.disabled__button]: isDisabled
              }
            ),
            leftIcon: styles.refreshButton__leftIcon,
          }}
          onClick={isTariffPage ? onClick : openModal}
          disabled={isTariffPage && isAddButtonPressed || isDisabled}
        >
          Все номера
        </Button>
        <Button
          leftIcon={<RefreshBtn color={isAddButtonPressed || isDisabled ? theme.colors.base[500] : theme.colors.base[0]} />}
          classNames={{
            root: cn(styles.button__root, styles.button__two, styles.buttons__next, {
              [styles.button__root__tariffPage]: isTariffPage,
            }),
            leftIcon: styles.refreshButton__leftIcon,
          }}
          onClick={handleNextNumberButtonClick}
          disabled={isAddButtonPressed || isDisabled}
        >
          {isTariffPage || isMobile ? 'Показать следующий' : 'Следующий'}
        </Button>
      </div>

      {bnModal && onShowForm && (
        <div className={styles.modal}>
          <BasketBeautifulNumber
            isOpened={bnModal}
            isMobile={isMobile}
            isDesktopMax={isDesktopMax}
            onClose={() => setBnModal(false)}
            handler={onShowForm}
            activeTariffData={activeTariffData}
          />
        </div>
      )}

      <div className={styles.modal}>
        <BeautifulNumberModal showModal={showModal} handleCloseModal={handleCloseModal} />
      </div>
    </div>
  );
}

export { BeautifulNumbers };
