export const OFFSET = 240;
export const HEIGHT = 48;
export const INDEX = 5; // индекс номера, который виден в данный момент на "барабане".

export const animationDelay: Record<number, string> = {
  4: '0.05s',
  5: '0.10s',
  7: '0.14s',
  8: '0.17s',
  9: '0.20s',
  11: '0.23s',
  12: '0.26s',
  14: '0.29s',
  15: '0.32s',
};

export const animationDuration: Record<number, string> = {
  4: '0.55s',
  5: '0.60s',
  7: '0.64s',
  8: '0.67s',
  9: '0.70s',
  11: '0.73s',
  12: '0.76s',
  14: '0.79s',
  15: '0.82s',
};

/* для акции "Черная пятница" */
export const categoryPriceMap : { [key: number]: string } = {
  104: '500',
  105: '1 000',
  106: '2 500',
  107: '5 000',
  108: '10 000',
  109: '30 000',
  110: '50 000',
  111: '200 000',
  112: '700 000',
};