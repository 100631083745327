import { SliceBeautifulNumber } from '@/store/slice/beautiful-number';
import { INDEX } from '@/widgets/beautiful-numbers/beautiful-numbers.constants';

export function getRandomNumbers() {
  const result: SliceBeautifulNumber[] = [];

  for (let i = 0; i < 11; i++) {
    let number = '';
    for (let j = 0; j < 10; j++) {
      const digit = randomIntFromInterval(0, 9);
      number += digit;
    }
    result.push({
      msisdn: number,
      price: 0,
      phone_id: 1,
      phone_type: 'Рандомный',
      pht_id: 0,
    });
  }

  return result;
}

export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function rollCylinder(numbers: SliceBeautifulNumber[]): SliceBeautifulNumber[] {
  if (numbers.length) {
    const lastElement = numbers[numbers.length - 1];
    const newArray = numbers.toSpliced(0, 0, lastElement);
    newArray.pop();

    return newArray;
  }

  return numbers;
}

export function replaceNumber(number: SliceBeautifulNumber, numbers: SliceBeautifulNumber[]): SliceBeautifulNumber[] {
  if (numbers.length) {
    const newArray = numbers.slice(0, numbers.length);
    newArray[INDEX] = number;

    return newArray;
  }

  return numbers;
}
