import { valueIsNumber } from '@/modules/tariff/shared/helpers';
import { useAppSelector } from '@/store/hooks';

export const useCountBasketPrices = (isEvpatoria?: boolean) => {
  const {
    totalPriceHomeInternet,
    promotionTotalPriceHomeInternet,
    status: statusHomeInternet,
  } = useAppSelector((store) => store.homeInternet);

  const {
    totalPriceMobile,
    promotionTotalPriceMobile,
    status: statusMobileTariff,
  } = useAppSelector((store) => store.mobileTariff);

  const {
    selectedNumber
  } = useAppSelector((store) => store.beautifulNumber);

  const totalBasketPrice =
    (statusHomeInternet.added ? totalPriceHomeInternet : 0) +
    (statusMobileTariff.added ? totalPriceMobile : 0) +
    (selectedNumber ? selectedNumber.price || 0 : 0);

  // console.log('NUMBER:', selectedNumber);

  const totalBasketPriceWithPromotion =
    (statusHomeInternet.added
      ? valueIsNumber(promotionTotalPriceHomeInternet)
        ? promotionTotalPriceHomeInternet || 0
        : totalPriceHomeInternet
      : 0) +
    (statusMobileTariff.added
      ? valueIsNumber(promotionTotalPriceMobile)
        ? promotionTotalPriceMobile || 0
        : totalPriceMobile
      : 0) +
    (selectedNumber ? selectedNumber.price || 0 : 0);

  return { totalBasketPrice, totalBasketPriceWithPromotion };
};
